import React, { useEffect } from 'react';
import { navigate, graphql } from 'gatsby';
import { GoldToppedHeader } from '../components/molecules';
import Mark from '../images/svg/amplified-mark.svg';
import SEO from '../components/seo';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export default function NotFoundPage() {
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
      <SEO
        title={t('Success')}
        description={t(
          "We've received your form submission and we'll be in touch soon."
        )}
      />
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <Link to="/" className="inline-flex">
            <span className="sr-only">Amplified</span>
            <Mark className="h-12 w-auto" alt={t('Amplified Logo')} />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <GoldToppedHeader header={t('Success!')} topper={t('Thank you')} />
            <p className="mt-2 text-base text-gray_4">
              <Trans>
                We've received your form submission and we'll be in touch soon.
              </Trans>
            </p>
            <div className="mt-6">
              <Link
                to="/"
                className="text-base font-medium text-bright_gold_120"
              >
                <Trans>
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </Trans>
              </Link>
            </div>
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="mailto:support@amplified.ai"
            className="text-sm font-medium text-chocolate"
          >
            <Trans>Contact Support</Trans>{' '}
          </a>
        </nav>
      </footer>
    </div>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
